<style scoped>
.main-containers {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(248 249 255);
}
.Livetop {
  background: rgba(212, 36, 34, 1);
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-bottom-right-radius: 200px 30px;
  border-bottom-left-radius: 200px 30px;
}

.liveSearch {
  background: rgba(218, 79, 78, 1);
  width: 90%;
  border-radius: 4px;
  margin: 0 auto;
  padding: 8px 20px;
  color: rgba(243, 183, 180, 1);
  display: flex;
  margin: 10px auto 10px;
  text-align: left;
}

.iconColor {
  color: rgba(243, 183, 180, 1);
}
.formItem {
  width: 90%;
}
</style>

<template>
  <div class="main-containers">
    <div class="main" style="width: 100vw; height: 100vh">
      <div class="Livetop">
        <div class="liveSearch">
          <div style="flex: 1">请输入会议信息...</div>
          <div>
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <div
        style="
          margin: -60px auto 20px;
          width: 100vw;
          height: 100vh;
          overflow-y: scroll;
        "
        v-if="formItem"
      >
        <Form
          :model="formItem"
          :label-width="80"
          style="
            width: 90%;
            margin: 0 auto 10px;
            background: white;
            padding: 40px 0;
            border-radius: 3px;
          "
        >
          <FormItem class="formItem" label="名称">
            <Input v-model="formItem.title" placeholder="直播名"></Input>
          </FormItem>
          <FormItem class="formItem" label="主持人">
            <Input v-model="formItem.author" placeholder="主持人"></Input>
          </FormItem>

          <FormItem class="formItem" label="日期">
            <DatePicker
              type="date"
              placeholder="日期"
              v-model="formItem.date"
            ></DatePicker>
          </FormItem>

          <FormItem class="formItem" label="时间">
            <Row>
              <Col span="11">
                <TimePicker
                  format="HH:mm"
                  type="time"
                  placeholder="开始时间"
                  v-model="formItem.startTime"
                ></TimePicker>
              </Col>
              <Col span="2" style="text-align: center">-</Col>
              <Col span="11">
                <TimePicker
                  format="HH:mm"
                  type="time"
                  placeholder="结束时间"
                  v-model="formItem.endTime"
                ></TimePicker>
              </Col>
              <Col span="11">
                <Checkbox v-model="formItem.liveFullDay">全天</Checkbox>
              </Col>
              <i span="11" style="font-size: 12px; color: #aaa"
                >选择全天播放，日期与时间无效</i
              >
            </Row>
          </FormItem>

          <FormItem label="Radio">
            <!-- '1':"尚未开始"  '2': "进行中"  3. "暂无状态" 4. "已结束" -->
            <RadioGroup v-model="formItem.liveStatus">
              <Radio label="1">尚未开始</Radio>
              <Radio label="2">进行中</Radio>
              <Radio label="3">暂无状态</Radio>
              <Radio label="4">已结束</Radio>
            </RadioGroup>
          </FormItem>

          <FormItem class="formItem" label="直播地址">
            <Poptip trigger="focus">
              <Input v-model="formItem.liveUrl" placeholder="直播地址"></Input>
              <div slot="content">{{ formatTrim }}</div>
            </Poptip>
          </FormItem>

          <FormItem label="Select">
            <Select v-model="formItem.liveType">
              <Option value="ARTC">ARTC</Option>
              <Option value="FLV">FLV</Option>
              <Option value="HLS">HLS</Option>
            </Select>
          </FormItem>

          <div style="margin-top: 40px; text-align: center">
            <Button
              style="box-shadow: 0px 15px 18px -6px rgb(95 193 206 / 65%)"
              type="primary"
              @click="addLiveList"
              >提交</Button
            >
            <Button
              type="error"
              style="
                margin-left: 8px;
                box-shadow: 0px 15px 18px -6px rgb(227 49 0 / 65%);
              "
              @click="delLive"
              v-if="userid == '13919267170'"
              >删除</Button
            >

            <Button
              style="
                margin-left: 8px;
                box-shadow: 0px 15px 18px -6px rgb(255 198 75 / 65%);
              "
              @click="cancel"
              >取消</Button
            >
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable  */
import { wxConPost } from "../api/httpApi";
import wxLoginApi from "../api/wxLoginApi";
import moment from "moment";

export default {
  name: "LiveList",
  data() {
    return {
      userid: "",
      formItem: {
        title: "",
        liveStatus: "3",
        date: "",
        time: "",
        startTime: "",
        endTime: "",
        author: "",
        liveType: "artc",
        liveUrl: "",
        liveFullDay: false,
      },
    };
  },
  computed: {
    formatTrim() {
      if (this.formItem && this.formItem.liveUrl) {
        return this.formItem.liveUrl.replace(/(^\s*)|(\s*$)/g, "");
      }
    },
  },
  methods: {
    cancel() {
      this.$router.push({
        path: "/",
      });
    },
    getLocalStorage(key1, exp) {
      const data = localStorage.getItem(key1);
      //转为对象
      const dataObj = data ? JSON.parse(data) : {};
      if (new Date().getTime() - dataObj.time > exp) {
        console.log("已过期", new Date().getTime(), dataObj.time);
        return false;
      } else {
        if (dataObj.data) {
          const dataObjDatatoJson = JSON.parse(dataObj.data);
          return dataObjDatatoJson;
        } else {
          return false;
        }
      }
    },
    async getUserData() {
      // 判断是否有取过数据
      const userData = this.getLocalStorage("liveUserData", this.exp);
      if (userData && !_.isEmpty(userData)) {
        // 通知数据获取成功
        return userData;
      }
      try {
        console.log(
          "---",
          userData,
          _.isEmpty(userData),
          _.isUndefined(userData)
        );
        if (_.isEmpty(userData) || _.isUndefined(userData)) {
          const result = await wxConPost(
            "/services/gttt-user-mgt/wx/getUserData",
            {}
          );
          _d("---------------------getUserData", result);
          // 数据写入store中
          this.setLocalStorage("liveUserData", JSON.stringify(result));
        }
        // 通知数据获取成功
        return result;
      } catch (e) {
        return {};
        _d("xxxxxxxxxxx获取用户其他数据Err", e);
      }
    },
    async addLiveList() {
      try {
        if (!this.formItem.title) {
          return;
        }
        if (this.formItem && this.formItem.liveUrl) {
          this.formItem.liveUrl = this.formItem.liveUrl.replace(
            /(^\s*)|(\s*$)/g,
            ""
          );
        }
        console.log("----", this.formItem);

        const ret = await wxConPost(
          "/services/gttt-live-mgt/wxLive/addLiveList",
          this.formItem
        );

        if (ret.ret == "ok") {
          this.$store.state.column = [];
          this.$router.push({
            path: "/",
          });
        }
      } catch (error) {
        console.log("----", error);
      }
    },
    async delLive() {
      try {
        if (!this.formItem._cid) {
          return;
        }

        const ret = await wxConPost(
          "/services/gttt-live-mgt/wxLive/delLiveList",
          { _cid: this.formItem._cid }
        );

        if (ret.ret == "ok") {
          this.$store.state.column = [];
          this.$router.push({
            path: "/",
          });
        }
      } catch (error) {
        console.log("----", error);
      }
    },
  },
  components: {},
  async created() {
    console.log("this.$route.query", this.$route.query);
    this.formItem = this.$route.query;
    if (this.$route.query.liveFullDay) {
      this.formItem.liveFullDay =
        this.$route.query.liveFullDay == "true" ? true : false;
    }

    wxLoginApi.onReady(async () => {
      const userInfo = await this.getUserData();
      console.log("----", userInfo);
      this.userid = userInfo.userid;
    });
  },
  beforeCreate() {
    document.title = "直播列表";
  },
  async destroyed() {},
};
</script>
